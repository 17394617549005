import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const resetPassword = async (password, token) => {
    const response = await axios.post(routes.resetPassword, {
        password, token
    }, AXIOS_DEFAULT_CONFIG);
    return response;
}
export default resetPassword;