import React, { useEffect } from "react";
import Loader from "../Utils/Loader";
import qs from "qs";

const PaymentConfirmationRedirect = ({ location, history }) => {
  useEffect(() => {
    let orderId = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_order_id;
    if (!orderId) {
      history.replace("/");
    } else {
      history.replace(`/orders/${orderId}`);
    }
  }, []);

  return (
    <div className="payment-redirect">
      <div className="payment-redirect__loader">
        <Loader />
      </div>
    </div>
  );
};

export default PaymentConfirmationRedirect;
