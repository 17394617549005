import React, { useState, useEffect, useRef } from "react";
import CheckoutReceipt from "../Receipt/CheckoutReceipt";
import { ReactComponent as KioskIcon } from "../../assets/images/kiosk.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/order-success-checkmark.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/input-error.svg";
import { ReactComponent as DownlaodIcon } from "../../assets/images/download.svg";
import { ReactComponent as ClockIcon } from "../../assets/images/clock.svg";
import { ReactComponent as CancelIcon } from "../../assets/images/cross.svg";
import VisaIcon from "../../assets/images/visa-icon.svg";
import MasterCardIcon from "../../assets/images/mastercard-icon.svg";
import SymplIcon from "./../../assets/images/sympl-logo.png";
import * as api from "../../api";
import { options as timeOptions } from "../../utils/timeFormat";
import { options as dateOptions } from "../../utils/dateFormat";
import { formatNumbers } from "../../utils/formatNumbers";
import ShareAndDirections from "../Utils/ShareAndDirections";
import { Link } from "react-router-dom";
import CTAPopup from "../Event/CTAPopup";
import { useHistory } from "react-router-dom";
import PaymentIframe from "./PayByCard";
import ExpiryPopup from "../Utils/ExpiryPopup";
import PaymentConfirmationEventBanner from "./../Event/PaymentConfirmationEventBanner";
import { hardcoded_event } from "./../../constants";
import PaymentSelectorPopup from "../Utils/PaymentSelectorPopup";

const PaymentConfirmation = ({
  match,
  user = {},
  setShowLogin = () => {},
  userLoading = false,
}) => {
  const [tickets, setTickets] = useState([]);
  const [addons, setAddons] = useState([]);
  const [event, setEvent] = useState({});
  const [status, setStatus] = useState({});
  const [grandTotal, setGrandTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [ticketCount, setTicketCount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [billReference, setBillReference] = useState("");
  const [ticketsFile, setTicketsFile] = useState(null);
  const [nextAction, setNextAction] = useState(null);
  const [showActionBar, setShowActionBar] = useState(false);
  const [expired, setExpired] = useState(false);
  const [expiry, setExpiry] = useState(null);
  const [cartToken, setCartToken] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [cardPaymentUrl, setCardPaymentUrl] = useState("");
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showPaymentSelector, setShowPaymentSelector] = useState(false);
  const [isPwf, setIsPwf] = useState(false);
  const failed = useRef(null);
  const history = useHistory();
  const statusMapper = {
    pending: "Pending Approval",
    rejected: "Rejected",
    rejected_ratio: "Rejected",
    paid: "Paid",
    approved: "Approved",
    partially_paid: "Pending friends payment",
    expired: "Expired",
    failed: "Payment Failed",
    cancelled: "Booking Cancelled",
    canceled: "Booking Cancelled",
    friend_pay_fail: "Pending friends payment",
  };
  const statusTypeMapper = {
    pending: "pending",
    rejected: "error",
    rejected_ratio: "error",
    paid: "success",
    approved: "pending",
    partially_paid: "pending",
    expired: "error",
    failed: "error",
    canceled: "error",
    cancelled: "error",
    friend_pay_fail: "pending",
  };

  const timeOutRef = useRef(null);

  useEffect(() => {
    getCartTotal();
  }, [tickets, addons]);

  useEffect(() => {
    if (!userLoading) {
      if (Object.keys(user).length === 0) {
        setShowLogin(true);
        setNextAction("loadOrder");
      } else {
        setShowLogin(false);
        setNextAction(null);
        fetchOrder();
      }
    }
  }, [userLoading, user]);

  useEffect(() => {
    if (Object.keys(user).length !== 0 && nextAction !== null) {
      handleNextAction(nextAction);
    }
  }, [user, nextAction]);

  const handleNextAction = (action) => {
    setNextAction(null);
    if (action === "loadOrder" && Object.keys(user).length !== 0) {
      fetchOrder();
    }
  };

  const fetchOrder = () => {
    if (hardcoded_event !== null) {
      fetchReservationOrder();
    } else {
      fetchTicketOrder();
    }
  };

  const fetchReservationOrder = () => {
    api.reservations
      .getReservationById(match.params.id)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setIsPwf(response?.data?.isPwf);
          let eventObj = {
            location: response.data.reservation.event.location,
            locationLink: response.data.reservation.event.locationLink,
            title: response.data.reservation.event.name,
            image: response.data.reservation.event.banner,
            time: new Date(
              response.data.reservation.event.startsAt
            ).toLocaleTimeString([], timeOptions),
            date: response.data.reservation.event.startsAt,
            type: response.data.reservation.event.reservationProcessType,
          };
          let addonsArray = /* response.data.addonItems.map((addon) => {
                return {
                  name: addon.name,
                  count: addon.quantity,
                  price: addon.addon.price / 100,
                };
              }) */ [];
          let ticketsArray = response.data.items.map((item) => {
            return {
              name:
                item.customer.firstName +
                " " +
                item.customer.lastName +
                " (" +
                item.name +
                ")",
              quantity: 1,
              price: item.ticketTier.price / 100,
            };
          });
          let status = response.data?.reservation?.status;
          if (
            status !== "paid" &&
            status !== "partially_paid" &&
            status !== "cancelled" &&
            status !== "canceled" &&
            status !== "rejected" &&
            status !== "rejected_ratio"
          ) {
            if (
              response.data.paymentStatus === "failed" ||
              response.data.paymentStatus === "pending"
            ) {
              status = "failed";
            }
          }
          if (timeOutRef !== null) {
            clearInterval(timeOutRef.current);
          }
          if (
            status === "partially_paid" &&
            response?.data?.paymentStatus !== "paid" &&
            (user?.pk !== response?.data?.reservation?.reservationHead?.pk ||
              (user?.pk === response?.data?.reservation?.reservationHead?.pk &&
                user?.pk !== response?.data?.customer?.pk))
          ) {
            status = "friend_pay_fail";
          }
          if (status === "failed") {
            setShowActionBar(true);
            if (response?.data?.cart) {
              let newExpiry = new Date(response.data.cart.expiresAt).getTime();
              timeOutRef.current = setInterval(() => {
                if (newExpiry - new Date().getTime() <= 0) {
                  setExpired(true);
                  setCardPaymentUrl("");
                  setPaymentLoading(false);
                }
              }, 1000);
              setExpiry(newExpiry);
            } else {
              setExpiry(null);
              setExpired(false);
            }
          }
          if (response?.data?.cartToken) {
            setCartToken(response.data.cartToken);
          }
          setEvent(eventObj);
          setAddons(addonsArray);
          setStatus({
            text: statusMapper[status],
            type: statusTypeMapper[status],
            original: status,
            reservation: response.data.reservation.status,
          });
          setTickets(ticketsArray);
          setPaymentMethod(response.data.paymentType);
          setReservationId(response.data.reservation.code);
          setOrderId(response.data.pk);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404 && !failed.current) {
          failed.current = true;
          fetchTicketOrder();
        }
      });
  };

  const fetchTicketOrder = () => {
    api.cart
      .getOrderItems(match.params.id)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          let ticketsArray = response.data.ticketItems.map((ticket) => {
            return {
              name: ticket.name,
              quantity: ticket.quantity,
              price: ticket.ticketTier.price / 100,
            };
          });
          let addonsArray = response.data.addonItems.map((addon) => {
            return {
              name: addon.name,
              count: addon.quantity,
              price: addon.addon.price / 100,
            };
          });
          let eventObj = {
            location: response.data.event.location,
            locationLink: response.data.event.locationLink,
            title: response.data.event.name,
            image: response.data.event.banner,
            time: new Date(response.data.event.startsAt).toLocaleTimeString(
              [],
              timeOptions
            ),
            date: response.data.event.startsAt,
            id: response.data.event.pk,
          };
          setTickets(ticketsArray);
          setAddons(addonsArray);
          setEvent(eventObj);
          setStatus({
            text:
              response.data.paymentStatus === "pending" &&
              response.data.paymentType === "kiosk"
                ? "Pending Payment"
                : statusMapper[response.data.paymentStatus],
            type: statusTypeMapper[response.data.paymentStatus],
            original: response.data.paymentStatus,
          });
          setPaymentMethod(response.data.paymentType);
          setBillReference(response.data.billReference);
          setTicketsFile(
            response.data.ticketsFile?.replace("http", "https") || null
          );
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404 && !failed.current) {
          failed.current = true;
          fetchReservationOrder();
        }
      });
  };

  const getCartTotal = () => {
    let sum = 0;
    let price = 0;
    for (let i = 0; i < tickets.length; i++) {
      sum += tickets[i].quantity;
      price += tickets[i].quantity * tickets[i].price;
    }
    for (let i = 0; i < addons.length; i++) {
      price += addons[i].price * addons[i].count;
    }
    setTicketCount(sum);
    setSubtotal(price);
  };

  const handleRetryPayment = (method) => {
    setPaymentLoading(true);
    api.reservations
      .payReservation(reservationId, orderId, method, false)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setCardPaymentUrl(response.data.cardPaymentUrl);
          setPaymentLoading(false);
        }
      })
      .catch(() => {
        setPaymentLoading(false);
      });
  };

  const cancelOrder = () => {
    if (!cartToken) {
      return;
    }
    if (timeOutRef !== null) {
      clearInterval(timeOutRef.current);
    }
    api.cart.cancelCart(cartToken).then((response) => {
      if (response.status && response.status >= 200 && response.status < 300) {
        setShowCancelConfirmation(false);
        fetchOrder();
        setShowActionBar(false);
        setExpired(false);
        setExpiry(null);
      }
    });
  };

  return (
    <React.Fragment>
      <div
        className={
          "payment-confirmation" +
          (showActionBar ? " payment-confirmation--with-action-bar" : "")
        }
      >
        <PaymentConfirmationEventBanner event={event} />
        <div className="payment-confirmation__details">
          <div className="payment-confirmation__title">
            <div className="payment-confirmation__title-container">
              {status?.type === "success" && (
                <SuccessIcon className="payment-confirmation__success-icon" />
              )}
              {status?.type === "error" && (
                <ErrorIcon className="payment-confirmation__error-icon" />
              )}
              {status?.type === "pending" && (
                <ClockIcon className="payment-confirmation__pending-icon" />
              )}
              <h3
                className={
                  "payment-confirmation__status" +
                  (status.type === "success"
                    ? " payment-confirmation__status-success"
                    : status.type === "error"
                    ? " payment-confirmation__status-error"
                    : " payment-confirmation__status-pending")
                }
              >
                {status?.text || ""}
              </h3>
            </div>
            {status?.original === "paid" && ticketsFile && (
              <a
                href={ticketsFile}
                download
                target="_blank"
                className="btn btn--secondary payment-confirmation__download-btn"
                rel="noreferrer"
              >
                <DownlaodIcon className="payment-confirmation__download-icon" />{" "}
                Download Tickets
              </a>
            )}
          </div>
          {/*   {status?.original === "failed" && (
          <p className="payment-confirmation__fail-paragraph">
            Please try again by clicking{" "}
            <Link
              to={`/booking/${event.id}`}
              className="payment-confirmation__fail-link"
            >
              here
            </Link>
          </p>
        )} */}
          {paymentMethod === "kiosk" && status?.original === "pending" && (
            <div className="payment-confirmation__kiosk-dialog">
              <p className="payment-confirmation__reference-number">
                {billReference}
              </p>
              <p className="payment-confirmation__reference-title">
                reference code
              </p>
              <div className="payment-confirmation__kiosk-dialog-status">
                <KioskIcon className="payment-confirmation__payment-method-icon" />
                To be paid Through Kiosk
              </div>
              <p className="payment-confirmation__kiosk-description">
                Head to your nearest Kiosk to pay your items using your
                Reference code ({billReference})
              </p>
            </div>
          )}
          {(status?.original === "failed" || status?.original === "pending") &&
            cartToken && (
              <div className="payment-confirmation__cancel-order-container">
                <div
                  className="payment-confirmation__cancel-order"
                  onClick={() => setShowCancelConfirmation(true)}
                >
                  <CancelIcon className="payment-confirmation__cancel-order-icon" />{" "}
                  Cancel Order
                </div>
              </div>
            )}
          <CheckoutReceipt
            tickets={tickets}
            subtotal={subtotal}
            addons={addons}
            setGrandTotal={setGrandTotal}
            grandTotal={grandTotal}
          />
          <div className="checkout__grand-total">
            <h3 className="checkout__grand-total-title">Grand total</h3>
            <div className="checkout__grand-total-value">
              <p>{formatNumbers(grandTotal)} EGP</p>
            </div>
          </div>
          {(paymentMethod === "card" || paymentMethod === "installment") &&
            (status?.original === "partially_paid" ||
              status?.original === "paid" ||
              status?.original === "failed" ||
              status?.original === "friend_pay_fail") && (
              <div className="payment-confirmation__payment-method">
                <div
                  className={
                    "payment-confirmation__payment-method-status" +
                    (status?.original === "failed" ||
                    status?.original === "friend_pay_fail"
                      ? " payment-confirmation__payment-method-status--error"
                      : " payment-confirmation__payment-method-status--success")
                  }
                >
                  {status?.original === "failed" ? (
                    <React.Fragment>Payment Failed</React.Fragment>
                  ) : status?.original === "friend_pay_fail" ? (
                    <React.Fragment>
                      Please visit the payment link sent to you via email to
                      secure your pass.
                    </React.Fragment>
                  ) : status?.original === "partially_paid" ? (
                    <React.Fragment>Your Payment Was Successful</React.Fragment>
                  ) : (
                    <React.Fragment>Payment Successful</React.Fragment>
                  )}
                  <div>
                    {paymentMethod === "installment" ? (
                      <img
                        src={SymplIcon}
                        className="payment-confirmation__payment-method-image"
                        alt="card"
                      />
                    ) : (
                      <React.Fragment>
                        <img
                          src={VisaIcon}
                          className="payment-confirmation__payment-method-image"
                          alt="card"
                        />
                        <img
                          src={MasterCardIcon}
                          className="payment-confirmation__payment-method-image"
                          alt="card"
                        />
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            )}
        </div>

        <PaymentIframe
          url={cardPaymentUrl}
          close={() => {
            setCardPaymentUrl("");
            setPaymentLoading(false);
          }}
          setLoading={setPaymentLoading}
          loading={paymentLoading}
        />
      </div>
      {showActionBar && (
        <CTAPopup
          actionHandler={() => {
            if (expired) {
              history.push(`/booking/${event.id}`);
            } else {
              if (!isPwf) {
                setShowPaymentSelector(true);
              } else {
                handleRetryPayment("card");
              }
            }
          }}
          expiry={expiry}
          text={expired ? "New Order" : "Retry Payment"}
          expired={expired}
        />
      )}
      {showCancelConfirmation && (
        <ExpiryPopup
          title={expiry !== null ? "Timer" : ""}
          message="By cancelling your order you will lose your fetched passes."
          actionMessage="Are you sure you want to cancel your order? "
          handleBtn1Click={() => setShowCancelConfirmation(false)}
          handleBtn2Click={cancelOrder}
          btn1="No, Dont't cancel my order"
          btn2="Yes, Cancel my order"
          closable={true}
          close={() => setShowCancelConfirmation(false)}
          expiry={expiry}
        />
      )}
      {showPaymentSelector && !expired && (
        <PaymentSelectorPopup
          handleSubmit={(method) => {
            handleRetryPayment(method);
            setShowPaymentSelector(false);
          }}
          close={() => setShowPaymentSelector(false)}
          eventType={event?.type}
        />
      )}
    </React.Fragment>
  );
};

export default PaymentConfirmation;
