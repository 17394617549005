import React from "react";
import { ReactComponent as DropdownIcon } from "../../assets/images/dropdown.svg";
import { ReactComponent as MinusBlack } from "../../assets/images/minus-black.svg";
import { ReactComponent as PlusBlack } from "../../assets/images/plus-black.svg";

const Addons = ({
  addons = [],
  selectedAddons = {},
  handleAddonChange = () => {},
  handleAddonCountChange = () => {},
  handleAddonCollapse = () => {},
}) => {
  return (
    <div className="addons-list">
      {addons.map((addon, index) => (
        <div
          className={
            "addon" + (selectedAddons[addon.id] ? " addon--selected" : "")
          }
          key={index}
        >
          <div className="addon__body">
            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={selectedAddons[addon.id] ? true : false}
                onChange={(e) =>
                  handleAddonChange(
                    e,
                    addon.id,
                    addon.price,
                    addon.name,
                    addon.type
                  )
                }
                id={"addon" + index}
              />
              <span className="checkmark"></span>
            </label>
            <div
              className="addon__base"
              onClick={() => handleAddonCollapse(index)}
              style={{ cursor: addon.description ? "pointer" : "default" }}
            >
              <div className="addon__info">
                <label htmlFor={"addon" + index} className="addon__name">
                  {addon.name}
                </label>
                <p className="addon__price">{addon.price} EGP</p>
              </div>
              {addon.type === "per unit" && (
                <div className="addon__counter">
                  <div
                    className="addon__counter-icon-wrapper"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddonCountChange(
                        addon.id,
                        "subtract",
                        addon.price,
                        addon.name
                      );
                    }}
                  >
                    <MinusBlack className="addon__counter-icon" />
                  </div>
                  {selectedAddons[addon.id]?.count || 0}
                  <div
                    className="addon__counter-icon-wraper"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddonCountChange(
                        addon.id,
                        "add",
                        addon.price,
                        addon.name
                      );
                    }}
                  >
                    <PlusBlack className="addon__counter-icon-wrapper" />
                  </div>
                </div>
              )}
              {addon.description && (
                <DropdownIcon
                  className={
                    "addon__dropdown-icon" +
                    (!addon.collapsed ? " addon__dropdown-icon--opened" : "")
                  }
                />
              )}
            </div>
          </div>
          {!addon.collapsed && addon.description && (
            <div className="addon__description">{addon.description}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Addons;
