import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as api from "../api";
import { ReactComponent as ErrorIcon } from "../assets/images/input-error.svg";

import qs from "qs";

const ResetPassword = ({ location = {}, history = {} }) => {
  const [formError, setFormError] = useState("");
  const [success, setSuccess] = useState(false);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const schema = yup.object({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(/^(?!.* )/, "Password can't contain spaces")
      .required("Password is required"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    let token = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).token;
    if (!token) {
      history.replace("/");
    } else {
      setToken(token);
    }
  }, []);

  const onSubmit = (data) => {
    setFormError("");
    setSuccess(false);
    setLoading(true);
    api.auth
      .resetPassword(data.password, token)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setSuccess(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400 &&
          error?.response?.data?.token
        ) {
          setFormError("This link is expired or invalid");
        } else {
          setFormError("Something went wrong");
        }
      });
  };

  return (
    <div className="forgot-password-page reset-password-page">
      <form
        className="forgot-password-page__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h3 className="registration-form__title">Reset your password</h3>
        {formError && (
          <p className="error-message registration-form__form-error">
            {formError}
          </p>
        )}
        {success && (
          <p className="forgot-password-page__paragraph success-message">
            Your password has been successfully reset
          </p>
        )}

        {!success ? (
          <React.Fragment>
            <div className="registration-form__input-group">
              <label
                htmlFor="email"
                className="registration-form__label registration-form__label--required"
              >
                Password
              </label>
              <div className="registration-form__input-box">
                <input
                  type="password"
                  id="password"
                  {...register("password")}
                  className={
                    "registration-form__input" +
                    (errors.password ? " registration-form__input--error" : "")
                  }
                />
                {errors.password && (
                  <ErrorIcon className="registration-form__input-icon" />
                )}
              </div>
              {errors?.password && (
                <p className="error-message registration-form__input-error-message">
                  {errors?.password?.message}
                </p>
              )}
            </div>
            <div className="registration-form__input-group">
              <label
                htmlFor="email"
                className="registration-form__label registration-form__label--required"
              >
                Confirm password
              </label>
              <div className="registration-form__input-box">
                <input
                  type="password"
                  id="passwordConfirmation"
                  {...register("passwordConfirmation")}
                  className={
                    "registration-form__input" +
                    (errors.passwordConfirmation
                      ? " registration-form__input--error"
                      : "")
                  }
                />
                {errors.passwordConfirmation && (
                  <ErrorIcon className="registration-form__input-icon" />
                )}
              </div>
              {errors?.passwordConfirmation && (
                <p className="error-message registration-form__input-error-message">
                  {errors?.passwordConfirmation?.message}
                </p>
              )}
            </div>
            <button
              type="submit"
              className="btn btn--primary registration-form__btn"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Reset password"}
            </button>
          </React.Fragment>
        ) : (
          <button
            type="button"
            className="btn btn--primary registration-form__btn"
            onClick={() => history.push("/")}
          >
            Find Passes
          </button>
        )}
      </form>
    </div>
  );
};

export default ResetPassword;
