import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const submitReservation = async (
    reservationHead,
    items,
    addonItems,
    event,
    type,
    isPwf,
    token,
    preRegister

) => {
    const response = await axios.post(
        routes.submitReservation,
        {
            reservationHead,
            event,
            items,
            addonItems,
            type,
            isPwf,
            cart_token: token,
            preRegister: preRegister || null
        },
        AXIOS_DEFAULT_CONFIG
    );
    return response;
};
export default submitReservation;