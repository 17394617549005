import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const anonymousRegister = async (email, firstName, lastName, gender, mobileNumber, token) => {
    const response = await axios.post(routes.anonymousRegister, {
        email, firstName, lastName, gender, mobileNumber, token
    }, AXIOS_DEFAULT_CONFIG);
    return response;
}
export default anonymousRegister;