import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/images/cross.svg";
import Overlay from "./Overlay";
import PaymentMethods from "./PaymentMethods";

const PaymentSelectorPopup = ({
  close = () => {},
  handleSubmit = () => {},
  eventType = "",
}) => {
  const [selectedPayment, setSelectedPayment] = useState();
  return (
    <React.Fragment>
      <div className="popup payment-selector">
        {<CloseIcon className="popup__close" onClick={close} />}
        <div className="popup__body payment-selector__body">
          <div className="payment-selector__header">
            <h3 className="payment-selector__title">
              Payment Method
            </h3>
          </div>
          <PaymentMethods
            selectedPayment={selectedPayment}
            handlePaymentMethodSelect={(value) => setSelectedPayment(value)}
            showSympl={/* eventType === "full" */ false}
          />
          <button
            className="btn btn--primary payment-selector__btn"
            onClick={() => selectedPayment && handleSubmit(selectedPayment)}
          >
            Pay
          </button>
          <button
            className="btn btn--secondary payment-selector__btn"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </div>
      <Overlay darkTheme={true} handleOnClick={close} />
    </React.Fragment>
  );
};

export default PaymentSelectorPopup;
