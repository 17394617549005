import React, { useEffect } from "react";
import qs from "qs";

const PresalesRedirect = ({ location = {}, history = {} }) => {
  useEffect(() => {
    let order_id = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_order_id;
    history.replace("/invitations/receipt/" + order_id);
  }, []);

  return <></>;
};

export default PresalesRedirect;
