import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const payCart = async (
  addonItems,
  token,
  type
) => {
  const response = await axios.post(
    routes.payCart,
    {
      addonItems,
      token,
      type,
    },
    AXIOS_DEFAULT_CONFIG
  );
  return response;
};
export default payCart;
