import React from "react";

const Overlay = ({ handleOnClick = () => {}, darkTheme = false }) => {
  return (
    <div
      className={"overlay" + (darkTheme ? " overlay--dark" : "")}
      onClick={() => handleOnClick()}
    ></div>
  );
};

export default Overlay;
