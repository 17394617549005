import React, { useEffect, useState } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { ReactComponent as CloseIcon } from "../../assets/images/cross.svg";

const ShareList = ({ close = () => {}, url = "", title = "" }) => {
  const [showShareList, setShowShareList] = useState(false);
  useEffect(() => {
    handleShareDialogue();
  }, []);

  const handleShareDialogue = () => {
    if (navigator.share) {
      let shareData = {
        title,
        text: "Get your passes now!",
        url: url,
      };
      navigator.share(shareData);
    } else {
      setShowShareList(!showShareList);
      setShowShareList(!showShareList);
    }
  };

  return (
    <React.Fragment>
      {showShareList && (
        <div className="share-list">
          <CloseIcon
            className="share-list__close-icon"
            onClick={() => close()}
          />
          <FacebookShareButton
            className="share-list__btn"
            url={window.location.origin + url}
          >
            <FacebookIcon round size="32" />
          </FacebookShareButton>
          <TwitterShareButton
            className="share-list__btn"
            url={window.location.origin + url}
          >
            <TwitterIcon round size="32" />
          </TwitterShareButton>
          <WhatsappShareButton
            className="share-list__btn"
            url={window.location.origin + url}
          >
            <WhatsappIcon round size="32" />
          </WhatsappShareButton>
        </div>
      )}
    </React.Fragment>
  );
};

export default ShareList;
