import React, { useEffect, useState } from "react";
import { ReactComponent as ErrorIcon } from "../../assets/images/input-error.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/input-success.svg";
import { ReactComponent as Dropdown } from "../../assets/images/dropdown.svg";
import validator from "validator";
import { isValidPhoneNumber } from "react-phone-number-input/mobile";
import { customStyles } from "../../utils/selectStyles";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import * as api from "../../api";

const GuestsForm = ({
  setExpanded = () => {},
  setGuestsMapper = () => {},
  setSelectedTickets = () => {},
  setUserLoading = () => {},
  selectedTickets = {},
  guestTemplate = {},
  guestsMapper = [],
  expanded = -1,
  invitationTickets = [],
  resHead = {},
}) => {
  const [ticketOptions, setTicketOptions] = useState([]);
  const [totalTickets, setTotalTickets] = useState(0);
  const [ticketsPool, setTicketsPool] = useState({});

  const genderOptions = [
    { label: "Male", value: "m" },
    { label: "Female", value: "f" },
  ];

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    let newOptions = [];
    let allTickets = { ...ticketsPool };
    for (const ticket in allTickets) {
      if (allTickets[ticket].quantity === 0) {
        newOptions = newOptions.filter(
          (item) => item.ticketTier !== allTickets[ticket].ticketTier
        );
      } else {
        newOptions.push({
          label:
            allTickets[ticket]?.tier?.name +
            " " +
            allTickets[ticket]?.tier?.nameLine2 +
            " " +
            allTickets[ticket]?.tier?.nameLine3 +
            " " +
            "(" +
            allTickets[ticket].quantity +
            " remaining)",
          value: allTickets[ticket].tier.pk,
          ticketTier: allTickets[ticket].tier.pk,
          price: allTickets[ticket].tier.price,
          name:
            allTickets[ticket]?.tier?.name +
            " " +
            allTickets[ticket]?.tier?.nameLine2 +
            " " +
            allTickets[ticket]?.tier?.nameLine3 +
            " ",
        });
      }
    }
    setTicketOptions(newOptions);
  }, [ticketsPool]);

  useEffect(() => {
    let guestsArea = document.getElementsByClassName(
      "presales-page__guests-area"
    )[0];
    let guestRow = document.getElementsByClassName("guest-item")[expanded];
    if (guestRow && guestsArea) {
      let scroll = guestRow.offsetTop;
      guestsArea.scroll({ top: scroll });
    }
  }, [expanded]);

  const handleDeleteGuest = (index) => {
    let ticketsCopy = { ...ticketsPool };
    let allGuests = [...guestsMapper];
    let current = { ...allGuests[index] };
    if (current?.ticket?.value) {
      let myCart = { ...selectedTickets };
      ticketsCopy[current.ticket.ticketTier].quantity += 1;
      if (myCart[current.ticket.ticketTier]) {
        if (myCart[current.ticket.ticketTier].quantity === 1) {
          delete myCart[current.ticket.ticketTier];
        } else {
          myCart[current.ticket.ticketTier].quantity -= 1;
        }
      }
      setSelectedTickets(myCart);
    }

    allGuests.splice(index, 1);
    if (expanded !== -1) {
      if (index === expanded) {
        setExpanded(-1);
      } else if (index < expanded) {
        setExpanded(expanded - 1);
      }
    }
    setTicketsPool(ticketsCopy);
    setGuestsMapper(allGuests);
  };

  const handleAddGuest = () => {
    let allGuests = [...guestsMapper];
    let newGuest = { ...guestTemplate };
    allGuests.push(newGuest);
    let pool = { ...ticketsPool };
    let myCart = { ...selectedTickets };
    if (Object.keys(pool).length === 1) {
      let ticketTier = Object.keys(pool)[0];
      let preselectedTicket = { ...pool[ticketTier] };
      newGuest.ticket = {
        label:
          preselectedTicket.tier.name +
          " " +
          preselectedTicket?.tier?.nameLine2 +
          " " +
          preselectedTicket?.tier?.nameLine3 +
          " ",
        value: preselectedTicket.tier.pk,
        ticketTier: preselectedTicket.tier.pk,
        price: preselectedTicket.tier.price,
        name:
          preselectedTicket.tier.name +
          " " +
          preselectedTicket?.tier?.nameLine2 +
          " " +
          preselectedTicket?.tier?.nameLine3 +
          " ",
        quantity: 1,
      };
      myCart[ticketTier].quantity += 1;
      setSelectedTickets(myCart);
      pool[ticketTier].quantity -= 1;
    }
    setTicketsPool(pool);
    setGuestsMapper(allGuests);
  };

  const init = () => {
    let mapper = [];
    let total = 0;
    let pool = {};
    let myCart = { ...selectedTickets };
    if (Object.keys(resHead).length === 0) {
      mapper.push({ ...guestTemplate });
    } else {
      mapper.push(resHead);
    }
    for (let i = 0; i < invitationTickets.length; i++) {
      total += invitationTickets[i].limit;
      pool[invitationTickets[i].tier.pk] = {
        ...invitationTickets[i],
        quantity: invitationTickets[i].limit,
      };
    }
    if (Object.keys(pool).length === 1) {
      let ticketTier = Object.keys(pool)[0];
      let preselectedTicket = { ...pool[ticketTier] };
      for (let i = 0; i < mapper.length; i++) {
        mapper[i].ticket = {
          label:
            preselectedTicket.tier.name +
            " " +
            preselectedTicket?.tier?.nameLine2 +
            " " +
            preselectedTicket?.tier?.nameLine3 +
            " ",
          value: preselectedTicket.tier.pk,
          ticketTier: preselectedTicket.tier.pk,
          price: preselectedTicket.tier.price,
          name:
            preselectedTicket.tier.name +
            " " +
            preselectedTicket?.tier?.nameLine2 +
            " " +
            preselectedTicket?.tier?.nameLine3 +
            " ",
          quantity: 1,
        };
      }
      myCart[ticketTier] = {
        label:
          preselectedTicket.tier.name +
          " " +
          preselectedTicket?.tier?.nameLine2 +
          " " +
          preselectedTicket?.tier?.nameLine3 +
          " ",
        value: preselectedTicket.tier.pk,
        ticketTier: preselectedTicket.tier.pk,
        price: preselectedTicket.tier.price,
        name:
          preselectedTicket.tier.name +
          " " +
          preselectedTicket?.tier?.nameLine2 +
          " " +
          preselectedTicket?.tier?.nameLine3 +
          " ",
        quantity: 1,
      };
      setSelectedTickets(myCart);
      pool[ticketTier].quantity -= 1;
    }
    setTicketsPool(pool);
    setGuestsMapper(mapper);
    setTotalTickets(total);
  };

  const handleGuestChange = (index, field, value) => {
    if (field === "email") {
      setUserLoading(true);
    }
    let allGuests = [...guestsMapper];
    let current = { ...allGuests[index] };
    let ticketsCopy = { ...ticketsPool };
    if (field === "ticket") {
      let myCart = { ...selectedTickets };
      if (current.ticket) {
        ticketsCopy[current.ticket.ticketTier].quantity += 1;
        if (myCart[current.ticket.ticketTier].quantity === 1) {
          delete myCart[current.ticket.ticketTier];
        } else {
          myCart[current.ticket.ticketTier].quantity -= 1;
        }
      }
      if (value) {
        ticketsCopy[value.ticketTier].quantity -= 1;
        if (myCart[value.ticketTier]?.quantity) {
          myCart[value.ticketTier].quantity += 1;
        } else {
          myCart[value.ticketTier] = value;
          myCart[value.ticketTier].quantity = 1;
        }
        current[field] = { ...value, label: value.name };
      } else {
        current[field] = null;
      }
      setSelectedTickets(myCart);
    } else {
      current[field] = value;
    }
    let errs = validateOnChange(
      field,
      value,
      { ...current.errors },
      current.registered
    );
    current.errors = errs;
    allGuests[index] = current;
    setTicketsPool(ticketsCopy);
    setGuestsMapper(allGuests);
  };

  const validateOnChange = (field, value, errors) => {
    let errs = { ...errors };
    switch (field) {
      case "email":
        if (!validator.isEmail(value)) {
          errs.email = "Email is invalid";
        } else {
          delete errs.email;
        }
        break;
      case "firstName":
        let firstName = value + "";
        if (!firstName) {
          errs.firstName = "First name is required";
        } else if (!value.match(/^[a-zA-Z]+$/)) {
          errs.firstName = "First name must only contain English letters";
        } else {
          delete errs.firstName;
        }
        break;
      case "lastName":
        let lastName = value + "";
        if (!lastName) {
          errs.lastName = "Last name is required";
        } else if (!value.match(/^[a-zA-Z]+$/)) {
          errs.lastName = "Last name must only contain English letters";
        } else {
          delete errs.lastName;
        }
        break;
      case "ticket":
        if (!value) {
          errs.ticket = "Select a ticket";
        } else {
          delete errs.ticket;
        }
        break;
      case "gender":
        if (!value) {
          errs.gender = "Select gender";
        } else {
          delete errs.gender;
        }
        break;
      case "phone":
        let phone = value || "";
        if (!isValidPhoneNumber(phone)) {
          errs.phone = "Phone is invalid";
        } else {
          delete errs.phone;
        }
        break;
      default:
        break;
    }
    return errs;
  };

  const fetchUser = (index, email) => {
    if (guestsMapper[index]?.errors?.email) {
      setUserLoading(false);
      return;
    }
    let allGuests = [...guestsMapper];
    let current = { ...allGuests[index] };
    api.users
      .fetchByEmail(email)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          current.firstName = response.data.firstName;
          current.lastName = response.data.lastName;
          current.phone = response.data.mobileNumber;
          current.gender = response.data?.gender
            ? response.data.gender === "m"
              ? {
                  label: "Male",
                  value: "m",
                }
              : { label: "Female", value: "f" }
            : null;
          current.registered = true;
          current.errors = {};
          current.pk = response.data.pk;
          allGuests[index] = current;
          setGuestsMapper(allGuests);
        } else {
          current.registered = false;
          current.firstName = "";
          current.lastName = "";
          current.phone = "";
          current.gender = null;
          current.errors = {};
          delete current.pk;
          allGuests[index] = current;
          setGuestsMapper(allGuests);
        }
        setUserLoading(false);
      })
      .catch(() => {
        current.registered = false;
        current.firstName = "";
        current.lastName = "";
        current.phone = "";
        current.gender = null;
        current.errors = {};
        delete current.pk;
        allGuests[index] = current;
        setGuestsMapper(allGuests);
        setUserLoading(false);
      });
  };

  return (
    <div className="presales-page__guests-area">
      {/* <h1 className="page-title">
        You've been selected for Sandbox Festival Pre-Sales
      </h1> */}
      <h4 className="checkout__subtitle">Enter your group information</h4>
      <p className="presales-page__description"></p>
      <div className="guests">
        {guestsMapper.map((guest, index) =>
          index === expanded ? (
            <div className="guests__row guest-item" key={index}>
              <div className="guests__title" onClick={() => setExpanded(-1)}>
                {index !== 0 && (
                  <button
                    className="btn btn--secondary guests__delete"
                    onClick={(e) => {
                      if (index !== 0) {
                        e.stopPropagation();
                        handleDeleteGuest(index);
                      }
                    }}
                  >
                    Remove
                  </button>
                )}
                Guest {index + 1}
                <div className="guests__expand" onClick={() => setExpanded(-1)}>
                  <Dropdown className="guests__expand-icon guests__expand-icon--expanded" />
                </div>
              </div>
              <div className="registration-form__input-group--double">
                <div className="registration-form__input-group">
                  <p className="registration-form__label registration-form__label--required">
                    Email
                  </p>
                  <div className="registration-form__input-box">
                    <input
                      type="text"
                      id="email"
                      className={
                        "registration-form__input" +
                        (guest?.errors?.email
                          ? " registration-form__input--error"
                          : "")
                      }
                      value={guest.email || ""}
                      disabled={
                        index === 0 &&
                        guest.email &&
                        guest.registered &&
                        Object.keys(resHead).length !== 0
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        handleGuestChange(index, "email", e.target.value)
                      }
                      onBlur={(e) => fetchUser(index, e.target.value)}
                    />
                    {guest?.errors?.email && (
                      <ErrorIcon className="registration-form__input-icon guests__status-icon--error" />
                    )}
                    {!guest?.errors?.email && guest.email && (
                      <SuccessIcon className="registration-form__input-icon guests__status-icon--success" />
                    )}
                  </div>
                  {guest?.errors?.email && (
                    <p className="error-message registration-form__input-error-message">
                      {guest.errors.email}
                    </p>
                  )}
                </div>
                <div className="registration-form__input-group">
                  <p
                    id="ticketLabel"
                    className="registration-form__label registration-form__label--required"
                  >
                    Select ticket
                  </p>
                  <div className="registration-form__input-box">
                    {guest.ticket && Object.keys(ticketsPool).length === 1 ? (
                      <React.Fragment>
                        <input
                          type="text"
                          value={guest.ticket.name}
                          disabled={true}
                          className={
                            "registration-form__input" +
                            (guest?.errors?.ticket
                              ? " registration-form__input--error"
                              : "")
                          }
                        />
                        <SuccessIcon className="registration-form__input-icon guests__status-icon--success" />
                      </React.Fragment>
                    ) : (
                      <Select
                        styles={customStyles(
                          guest?.errors?.ticket ? "error" : "default"
                        )}
                        value={guest.ticket || null}
                        options={ticketOptions}
                        onChange={(selected) =>
                          handleGuestChange(index, "ticket", selected)
                        }
                        className="guests__select-input"
                        classNamePrefix="guests__select-input"
                        isClearable
                        isSearchable={false}
                      />
                    )}
                  </div>
                  {guest?.errors?.ticket && (
                    <p className="error-message registration-form__input-error-message">
                      {guest.errors.ticket}
                    </p>
                  )}
                </div>
              </div>
              <div className="registration-form__input-group--double">
                <div className="registration-form__input-group">
                  <p className="registration-form__label registration-form__label--required">
                    First Name
                  </p>
                  <div className="registration-form__input-box">
                    <input
                      type="text"
                      id="firstName"
                      className={
                        "registration-form__input" +
                        (guest?.errors?.firstName
                          ? " registration-form__input--error"
                          : "")
                      }
                      value={guest.firstName || ""}
                      disabled={
                        guest.registered && guest.firstName ? true : false
                      }
                      onChange={(e) =>
                        handleGuestChange(index, "firstName", e.target.value)
                      }
                    />
                    {guest?.errors?.firstName && (
                      <ErrorIcon className="registration-form__input-icon guests__status-icon--error" />
                    )}
                    {!guest?.errors?.firstName && guest.firstName && (
                      <SuccessIcon className="registration-form__input-icon guests__status-icon--success" />
                    )}
                  </div>
                  {guest?.errors?.firstName && (
                    <p className="error-message registration-form__input-error-message">
                      {guest.errors.firstName}
                    </p>
                  )}
                </div>
                <div className="registration-form__input-group">
                  <p className="registration-form__label registration-form__label--required">
                    Last Name
                  </p>
                  <div className="registration-form__input-box">
                    <input
                      type="text"
                      id="lastName"
                      className={
                        "registration-form__input" +
                        (guest?.errors?.lastName
                          ? " registration-form__input--error"
                          : "")
                      }
                      value={guest.lastName || ""}
                      disabled={
                        guest.registered && guest.lastName ? true : false
                      }
                      onChange={(e) =>
                        handleGuestChange(index, "lastName", e.target.value)
                      }
                    />
                    {guest?.errors?.lastName && (
                      <ErrorIcon className="registration-form__input-icon guests__status-icon--error" />
                    )}
                    {!guest?.errors?.lastName && guest.lastName && (
                      <SuccessIcon className="registration-form__input-icon guests__status-icon--success" />
                    )}
                  </div>
                  {guest?.errors?.lastName && (
                    <p className="error-message registration-form__input-error-message">
                      {guest.errors.lastName}
                    </p>
                  )}
                </div>
              </div>
              <div className="registration-form__input-group--double">
                <div className="registration-form__input-group">
                  <p className="registration-form__label registration-form__label--required">
                    Gender
                  </p>
                  <div className="registration-form__input-box">
                    {guest.registered ? (
                      <input
                        type="text"
                        value={guest?.gender?.label}
                        disabled={true}
                        className={
                          "registration-form__input" +
                          (guest?.errors?.gender
                            ? " registration-form__input--error"
                            : "")
                        }
                      />
                    ) : (
                      <Select
                        styles={customStyles(
                          guest?.errors?.gender ? "error" : "default"
                        )}
                        options={genderOptions}
                        value={guest.gender || null}
                        onChange={(selected) =>
                          handleGuestChange(index, "gender", selected)
                        }
                        className={
                          "guests__select-input" +
                          (guest?.errors?.gender
                            ? " guests__select-input--error"
                            : guest.gender
                            ? " guests__select-input--success"
                            : "")
                        }
                        classNamePrefix="guests__select-input"
                        isSearchable={false}
                      />
                    )}
                    {guest?.errors?.gender && (
                      <ErrorIcon className="registration-form__input-icon guests__status-icon--error" />
                    )}
                    {!guest?.errors?.gender && guest.gender && (
                      <SuccessIcon className="registration-form__input-icon guests__status-icon--success" />
                    )}
                  </div>
                  {guest?.errors?.gender && (
                    <p className="error-message registration-form__input-error-message">
                      {guest.errors.gender}
                    </p>
                  )}
                </div>
                <div className="registration-form__input-group">
                  <p className="registration-form__label registration-form__label--required">
                    Phone
                  </p>
                  <div className="registration-form__input-box">
                    {guest.registered ? (
                      <input
                        type="text"
                        value={guest.phone}
                        disabled={true}
                        className={
                          "registration-form__input" +
                          (guest?.errors?.phone
                            ? " registration-form__input--error"
                            : "")
                        }
                      />
                    ) : (
                      <PhoneInput
                        international={true}
                        value={guest.phone || ""}
                        onChange={(value) =>
                          handleGuestChange(index, "phone", value)
                        }
                        disabled={
                          guest.registered && guest.phone ? true : false
                        }
                        className={
                          guest?.errors?.phone ? "PhoneInput--error" : ""
                        }
                      />
                    )}
                    {guest?.errors?.phone && (
                      <ErrorIcon className="registration-form__input-icon guests__status-icon--error" />
                    )}
                    {!guest?.errors?.phone && guest.phone && (
                      <SuccessIcon className="registration-form__input-icon guests__status-icon--success" />
                    )}
                  </div>
                  {guest?.errors?.phone && (
                    <p className="error-message registration-form__input-error-message">
                      {guest.errors.phone}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="guests__collapsed-row guest-item"
              key={index}
              onClick={() => setExpanded(index)}
            >
              {index !== 0 && (
                <button
                  className="btn btn--secondary guests__delete"
                  onClick={(e) => {
                    if (index !== 0) {
                      e.stopPropagation();
                      handleDeleteGuest(index);
                    }
                  }}
                >
                  Remove
                </button>
              )}
              <div className="guests__title">Guest {index + 1}</div>
              <div className="guests__status">
                {guest.email &&
                guest.ticket &&
                guest.firstName &&
                guest.lastName &&
                guest.gender &&
                guest.phone &&
                Object.keys(guest?.errors)?.length === 0 ? (
                  <SuccessIcon className="guests__status-icon guests__status-icon--success" />
                ) : (
                  <ErrorIcon className="guests__status-icon guests__status-icon--error" />
                )}
              </div>
              <div className="guests__name">
                {guest.firstName && guest.lastName
                  ? guest.firstName + " " + guest.lastName
                  : "ENTER INFO"}
              </div>
              <div className="guests__ticket">
                {guest?.ticket?.label ? guest.ticket.label : "SELECT TICKET"}
              </div>
              <div className="guests__expand">
                <Dropdown className="guests__expand-icon" />
              </div>
            </div>
          )
        )}
      </div>
      {guestsMapper.length < totalTickets && (
        <p className="guests__add-btn" onClick={() => handleAddGuest()}>
          + ADD GUEST
        </p>
      )}
    </div>
  );
};

export default GuestsForm;
