import React from "react";
import ShareAndDirections from "./../Utils/ShareAndDirections";

const PaymentConfirmationEventBanner = ({ event = {} }) => {
  return (
    <div className="payment-confirmation__event">
      <img
        src={event?.image}
        alt="event"
        className="payment-confirmation__event-image"
      />
      <div className="payment-confirmation__event-image-text">
        {/* <div className="payment-confirmation__share-and-directions">
          <ShareAndDirections
            locationLink={event?.locationLink}
            url={`/booking/${event?.id}`}
            image={event?.image}
          />
        </div> */}
        <p className="payment-confirmation__event-image-date">
          {event?.date
            ? new Date(event.date).toLocaleString("default", {
                day: "numeric",
              }) +
              " " +
              new Date(event.date).toLocaleString("default", {
                month: "short",
              }) +
              ","
            : ""}
          {event?.location}
        </p>
        <h3 className="payment-confirmation__event-image-title">
          {event?.title}
        </h3>
      </div>
    </div>
  );
};

export default PaymentConfirmationEventBanner;
