import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const sendInvitationOrder = async (id, body, portal) => {
    const methodMap = {
        order: "post",
        purchase: "put"
    }
    const response = await axios[methodMap[portal]](routes.sendInvitationOrder(id, portal), { ...body }, AXIOS_DEFAULT_CONFIG);
    return response;
};
export default sendInvitationOrder;
