import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const getReservationsById = async (id
) => {
    const response = await axios.get(
        routes.getReservationsById(id), AXIOS_DEFAULT_CONFIG
    );
    return response;
};
export default getReservationsById;