import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const getEventById = async (id) => {
  const response = await axios.get(
    routes.getEventById(id),
    AXIOS_DEFAULT_CONFIG
  );
  return response;
};
export default getEventById;
