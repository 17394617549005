import React, { useEffect, useState } from "react";
import { warnAt } from "../../constants";
import { getDuration } from "../../utils/getDuration";

const CountDown = ({ expiry }) => {
  const [remainingTime, setRemainigTime] = useState();

  useEffect(() => {
    setRemainigTime(getDuration(expiry) || 0);
    const intervalRef = setInterval(() => {
      if (getDuration(expiry) <= 0) clearInterval(intervalRef);
      setRemainigTime(getDuration(expiry));
    }, 1000);

    return () => {
      clearInterval(intervalRef);
    };
  }, [expiry]);

  if (remainingTime <= 0) {
    return (
      <h3 className="countdown__expired countdown__expired--danger">Expired</h3>
    );
  }
  let minutes = (((remainingTime % 3600) / 60) | 0) + "";
  let seconds = (remainingTime % 60 | 0) + "";
  let warn = false;
  if (Number(minutes) < warnAt) {
    warn = true;
  }
  if (minutes.length === 1) {
    minutes = "0" + minutes;
  }
  if (seconds.length === 1) {
    seconds = "0" + seconds;
  }

  return (
    <div className="countdown">
      <div className="countdown__time-col">
        <div
          className={
            "countdown__time" + (warn ? " countdown__time--danger" : "")
          }
        >
          {minutes}
        </div>
        <div className="countdown__label">MIN</div>
      </div>
      <div
        className={
          "countdown__separator" + (warn ? " countdown__separator--danger" : "")
        }
      >
        :
      </div>
      <div className="countdown__time-col">
        <div
          className={
            "countdown__time" + (warn ? " countdown__time--danger" : "")
          }
        >
          {seconds}
        </div>
        <div className="countdown__label">SEC</div>
      </div>
    </div>
  );
};

export default CountDown;
