import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ReactComponent as ErrorIcon } from "../../assets/images/input-error.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/input-success.svg";
import * as api from "../../api";

const ChangePassword = ({ user = {}, handleLogin = () => {} }) => {
  const [formState, setFormState] = useState({});

  const timerRef = useRef(null);

  const schema = yup.object({
    oldPassword: yup.string().required("Password is required"),
    newPassword: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(/^(?!.* )/, "Password can't contain spaces")
      .required("Password is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    api.auth
      .changePassword(data.oldPassword, data.newPassword)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          handleLogin(user.email, data.newPassword);
          setValue("oldPassword", "", { shouldValidate: false });
          setValue("newPassword", "", { shouldValidate: false });
          setFormState({
            type: "success",
            message: "Password Successfully Changed",
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          if (err?.response?.data?.password) {
            setError("oldPassword", {
              type: "manual",
              message: "Old password is incorrect",
            });
          } else if (err?.response?.data?.newPassword) {
            setError("newPassword", {
              type: "manual",
              message: "New password can't be old password",
            });
          }
        } else {
          setFormState({ type: "fail", message: "Something went wrong" });
        }
      });
  };

  useEffect(() => {
    if (timerRef !== null) {
      clearTimeout(timerRef.current);
    }
    if (Object.keys(formState).length !== 0) {
      timerRef.current = setTimeout(() => {
        setFormState({});
      }, 10000);
    }
    return () => {
      if (timerRef !== null) {
        clearTimeout(timerRef.current);
      }
    };
  }, [formState]);

  return (
    <form className="profile__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="registration-form__input-group--double">
        <div className="registration-form__input-group">
          <p className="registration-form__label registration-form__label--required">
            Old Password
          </p>
          <div className="registration-form__input-box">
            <input
              type="password"
              id="oldPassword"
              {...register("oldPassword")}
              className={
                "registration-form__input" +
                (errors.oldPassword ? " registration-form__input--error" : "")
              }
            />
            {errors.oldPassword && (
              <ErrorIcon className="registration-form__input-icon" />
            )}
            {!errors.oldPassword &&
              dirtyFields.oldPassword &&
              getValues("oldPassword") && (
                <SuccessIcon className="registration-form__input-icon" />
              )}
          </div>
          {errors?.oldPassword && (
            <p className="error-message registration-form__input-error-message">
              {errors?.oldPassword?.message}
            </p>
          )}
        </div>
        <div className="registration-form__input-group">
          <p className="registration-form__label registration-form__label--required">
            New Password
          </p>
          <div className="registration-form__input-box">
            <input
              type="password"
              id="newPassword"
              {...register("newPassword")}
              className={
                "registration-form__input" +
                (errors.newPassword ? " registration-form__input--error" : "")
              }
            />
            {errors.newPassword && (
              <ErrorIcon className="registration-form__input-icon" />
            )}
            {!errors.newPassword &&
              dirtyFields.newPassword &&
              getValues("newPassword") && (
                <SuccessIcon className="registration-form__input-icon" />
              )}
          </div>
          {errors?.newPassword && (
            <p className="error-message registration-form__input-error-message">
              {errors?.newPassword?.message}
            </p>
          )}
        </div>
      </div>
      <div className="profile__form-btn-container">
        {Object.keys(formState).length !== 0 && (
          <p
            className={
              "profile__form-message" +
              (formState.type === "success"
                ? " success-message"
                : formState.type === "fail"
                ? " error-message"
                : "")
            }
          >
            {formState.message}
          </p>
        )}
        <button type="submit" className="btn btn--primary profile__form-btn">
          Save
        </button>
      </div>
    </form>
  );
};

export default ChangePassword;
