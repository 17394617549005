import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const payReservationAnonymously = async (reservation, order, type, token, sendEmail) => {
  const response = await axios.post(
    routes.payReservationAnonymously(reservation, order),
    {
      type,
      token,
      sendEmail,
    },
    AXIOS_DEFAULT_CONFIG
  );
  return response;
};
export default payReservationAnonymously;
