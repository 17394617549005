import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const cancelCart = async (token) => {
    const response = await axios.post(routes.cancelCart, {
        token
    }, AXIOS_DEFAULT_CONFIG);
    return response;
}
export default cancelCart;