import React from "react";
import CountDown from "../Utils/CountDown";
import Loader from "../Utils/Loader";
import Overlay from "../Utils/Overlay";
import Tooltip from "../Utils/Tooltip";

const CTAPopup = ({
  actionHandler = () => {},
  title = "",
  variation = "single",
  text = "",
  textIcon = null,
  text2 = "",
  disabled = false,
  expiry = null,
  displayMessage = "",
  loading = false,
  showOverlay = false,
  cancel = () => {},
  warning = "",
  tooltipShown = false,
  overlayClose = () => {},
  isHidden = false,
  expired = false,
}) => {
  return (
    <React.Fragment>
      <div
        className={
          "cta-popup" +
          (showOverlay ? " highest-z-index" : "") +
          (warning ? " cta-popup--warning" : "") +
          (isHidden ? " cta-popup--hidden" : "")
        }
      >
        <div className="cta-popup__text-area">
          {(variation === "error" || variation === "error-single") && (
            <h3 className="cta-popup__warning">{warning}</h3>
          )}
          {!displayMessage && <h3 className="cta-popup__title">{title}</h3>}
          {displayMessage && (
            <p className="cta-popup__display-message">{displayMessage}</p>
          )}
        </div>
        {expiry && (
          <React.Fragment>
            <div className="cta-popup__timer">
              {!expired && (
                <Tooltip
                  title="timer"
                  message="We will be holding your tickets for this amount of time. Until you checkout successfully."
                  tooltipShown={tooltipShown}
                />
              )}
              <CountDown expiry={expiry}/>
            </div>
          </React.Fragment>
        )}
        {(variation === "double" || variation === "single") &&
          variation !== "no-actions" && (
            <div className="cta-popup__btn-box">
              <button
                className={
                  "btn btn--primary cta-popup__btn" +
                  (disabled ? " cta-popup__btn--disabled" : "")
                }
                onClick={() => actionHandler()}
                disabled={disabled}
              >
                <div
                  className={
                    "cta-popup__btn--1" +
                    (textIcon !== null ? " cta-popup__btn--width-icon" : "")
                  }
                >
                  {text} {textIcon}
                </div>
                {variation === "double" && (
                  <React.Fragment>
                    <div className="btn-line"></div>
                    <div className="cta-popup__btn--2">{text2}</div>
                  </React.Fragment>
                )}
              </button>
            </div>
          )}
        {variation === "error" && (
          <div className="cta-popup__btn-box">
            <button
              className="btn btn--secondary cta-popup__btn cta-popup__error-btn cta-popup__error-btn--cancel"
              onClick={() => cancel()}
            >
              {text}
            </button>
            <button
              className="btn btn--primary cta-popup__btn cta-popup__error-btn"
              onClick={() => actionHandler()}
            >
              {text2}
            </button>
          </div>
        )}
        {variation === "error-single" && (
          <div className="cta-popup__btn-box">
            <button
              className="btn btn--primary cta-popup__btn cta-popup__error-btn cta-popup__error-btn--single"
              onClick={() => actionHandler()}
            >
              {text}
            </button>
          </div>
        )}
        {loading && <Loader />}
      </div>
      {showOverlay && <Overlay handleOnClick={overlayClose} />}
    </React.Fragment>
  );
};

export default CTAPopup;
