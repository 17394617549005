import React, { useEffect, useState } from "react";
import { commission, VAT, entertainmentTax } from "./../../constants";
import { formatNumbers } from "./../../utils/formatNumbers";

const CheckoutReceipt = ({
  tickets = [],
  subtotal = 0,
  addons = [],
  setGrandTotal = () => {},
  showReceipt = true,
  grandTotal = "",
  isFoc = null,
}) => {
  let [total, setTotal] = useState(0);
  let [commissionValue, setCommissionValue] = useState(0);
  let [enterTaxValue, setEnterTaxValue] = useState(0);

  useEffect(() => {
    let total = 0;
    let comm = subtotal * commission;
    let entTax = subtotal * entertainmentTax;
    total = subtotal /* + comm + entTax */;
    let v = total; /* + total * VAT */
    setGrandTotal(v);
    setTotal(total);
    setEnterTaxValue(entTax);
    setCommissionValue(comm);
  }, [subtotal]);

  return (
    <div className="checkout__receipt">
      {showReceipt && (
        <table className="receipt-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
              <th>Sum</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket, index) => (
              <tr key={index}>
                <td>{ticket.name}</td>
                <td>{ticket.quantity}</td>
                <td>
                  {isFoc === true
                    ? "Free"
                    : formatNumbers(ticket.price * ticket.quantity) + " EGP"}
                </td>
              </tr>
            ))}
            {addons.map((addon, index) => (
              <tr key={index}>
                <td>{addon.name}</td>
                <td>
                  {addon.type !== "bulk" && addon.type !== "fixed"
                    ? addon.count
                    : ""}
                </td>
                <td>{formatNumbers(addon.count * addon.price)} EGP</td>
              </tr>
            ))}
            {/* <tr className="receipt-table__subtotal-tr">
              <td className="receipt-table__subtotal-text">Subtotal</td>
              <td></td>
              <td className="receipt-table__subtotal-text">{subtotal} EGP</td>
            </tr> */}
            {/* <tr>
              <td>Comission</td>
              <td></td>
              <td>{formatNumbers(commissionValue)} EGP</td>
            </tr>
            <tr>
              <td>Entertainment Tax</td>
              <td></td>
              <td>{formatNumbers(enterTaxValue)} EGP</td>
            </tr>
            <tr>
              <td>VAT</td>
              <td></td>
              <td>{formatNumbers(total * VAT)} EGP</td>
            </tr> */}
          </tbody>
        </table>
      )}
      <div className="checkout__grand-total">
        <h3 className="checkout__grand-total-title">Grand total</h3>
        <div className="checkout__grand-total-value">
          <p>{isFoc === true ? "Free" : formatNumbers(grandTotal) + " EGP"}</p>
        </div>
        {/*   <div
          className={
            "checkout__receipt-toggle" +
            (showReceipt ? " checkout__receipt-toggle--opened" : "")
          }
          onClick={() => setShowReceipt(!showReceipt)}
        >
          {formatNumbers(grandTotal)} EGP
          {showReceipt ? (
            <DropdownIconRed className="checkout__receipt-icon" />
          ) : (
            <DropdownIcon className="checkout__receipt-icon" />
          )}
        </div> */}
      </div>
    </div>
  );
};

export default CheckoutReceipt;
