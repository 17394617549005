import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidPhoneNumber } from "react-phone-number-input/mobile";
import * as yup from "yup";
import { ReactComponent as ErrorIcon } from "../../assets/images/input-error.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/input-success.svg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Select from "react-select";
import { customStyles } from "./../../utils/selectStyles";
import * as api from "../../api";

const ChangeInfo = ({ user = {}, setUser = () => {} }) => {
  const [formState, setFormState] = useState({});

  const timerRef = useRef(null);

  const genderOptions = [
    { label: "Male", value: "m" },
    { label: "Female", value: "f" },
  ];

  yup.addMethod(yup.string, "phone", function (errorMessage) {
    return this.test("test-phone", errorMessage, function (value) {
      const { path, createError } = this;
      if (!value) {
        return false;
      }
      if (isValidPhoneNumber(value)) {
        return true;
      } else {
        createError({ path, message: errorMessage });
        return false;
      }
    });
  });

  const schema = yup.object({
    firstName: yup.string().required("First name is Required"),
    lastName: yup.string().required("Last name is Required"),
    phone: yup.string().phone("Invalid number").required("Phone is required"),
    gender: yup
      .object()
      .shape({
        value: yup.string().required("Please select gender"),
        label: yup.string().required("Please select gender"),
      })
      .nullable()
      .required("Please select gender"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      setValue("firstName", user.firstName, { shouldValidate: false });
      setValue("lastName", user.lastName, { shouldValidate: false });
      setValue("email", user.email, { shouldValidate: false });
      setValue("phone", user.mobile, { shouldValidate: false });
      setValue("gender", user.gender, { shouldValidate: false });
    }
  }, [user]);

  useEffect(() => {
    if (timerRef !== null) {
      clearTimeout(timerRef.current);
    }
    if (Object.keys(formState).length !== 0) {
      timerRef.current = setTimeout(() => {
        setFormState({});
      }, 10000);
    }
    return () => {
      if (timerRef !== null) {
        clearTimeout(timerRef.current);
      }
    };
  }, [formState]);

  const onSubmit = (data) => {
    api.auth
      .changeInfo(data.firstName, data.lastName, data.phone, data.gender?.value)
      .then((response) => {
        let genderObject = {};
        if (response.data.gender === "f") {
          genderObject.label = "Female";
          genderObject.value = "f";
        } else if (response.data.gender === "m") {
          genderObject.label = "Male";
          genderObject.value = "m";
        }
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setFormState({
            type: "success",
            message: "Profile Updated Successfully",
          });
          setUser({
            ...user,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            mobile: response.data.mobileNumber,
            gender: genderObject,
          });
        }
      })
      .catch((err) => {
        setFormState({
          type: "fail",
          message: "Something went wrong",
        });
      });
  };
  return (
    <form className="profile__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="registration-form__input-group--double">
        <div className="registration-form__input-group">
          <p className="registration-form__label registration-form__label--required">
            First Name
          </p>
          <div className="registration-form__input-box">
            <input
              type="text"
              id="firstName"
              {...register("firstName")}
              className={
                "registration-form__input" +
                (errors.firstName ? " registration-form__input--error" : "")
              }
            />
            {errors.firstName && (
              <ErrorIcon className="registration-form__input-icon" />
            )}
            {!errors.firstName && dirtyFields.firstName && (
              <SuccessIcon className="registration-form__input-icon" />
            )}
          </div>
          {errors?.firstName && (
            <p className="error-message registration-form__input-error-message">
              {errors?.firstName?.message}
            </p>
          )}
        </div>
        <div className="registration-form__input-group">
          <p className="registration-form__label registration-form__label--required">
            Last Name
          </p>
          <div className="registration-form__input-box">
            <input
              type="text"
              id="lastName"
              {...register("lastName")}
              className={
                "registration-form__input" +
                (errors.lastName ? " registration-form__input--error" : "")
              }
            />
            {errors.lastName && (
              <ErrorIcon className="registration-form__input-icon" />
            )}
            {!errors.lastName && dirtyFields.lastName && (
              <SuccessIcon className="registration-form__input-icon" />
            )}
          </div>
          {errors?.lastName && (
            <p className="error-message registration-form__input-error-message">
              {errors?.lastName?.message}
            </p>
          )}
        </div>
      </div>
      <div className="registration-form__input-group--double">
        <div className="registration-form__input-group">
          <p className="registration-form__label registration-form__label--required">
            Email
          </p>
          <div className="registration-form__input-box">
            <input
              type="text"
              id="email"
              className="registration-form__input"
              value={user.email}
              onChange={() => {}}
              disabled={true}
            />
            {user.email && (
              <SuccessIcon className="registration-form__input-icon" />
            )}
          </div>
        </div>
        <div className="registration-form__input-group">
          <label
            htmlFor="phone"
            className="registration-form__label registration-form__label--required"
          >
            Phone
          </label>
          <div className="registration-form__input-box">
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <PhoneInput
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  defaultCountry="EG"
                  international={true}
                  className={errors.phone ? "PhoneInput--error" : ""}
                />
              )}
            />
            {errors.phone && (
              <ErrorIcon className="registration-form__input-icon" />
            )}
            {!errors.phone && dirtyFields.phone && (
              <SuccessIcon className="registration-form__input-icon" />
            )}
          </div>
          {errors?.phone && (
            <p className="error-message registration-form__input-error-message">
              {errors?.phone?.message}
            </p>
          )}
        </div>
      </div>

      <div className="registration-form__input-group--half">
        <div className="registration-form__input-group">
          <label
            htmlFor="gender"
            className="registration-form__label registration-form__label--required"
          >
            Gender
          </label>
          <div className="registration-form__input-box">
            <Controller
              control={control}
              name="gender"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  options={genderOptions}
                  styles={
                    errors.gender
                      ? customStyles("error")
                      : customStyles("default")
                  }
                  isSearchable={false}
                  isClearable={false}
                  className="registration-form__select-input"
                  classNamePrefix="registration-form__select-input"
                />
              )}
            />
          </div>
          {errors?.gender && (
            <p className="error-message registration-form__input-error-message">
              {errors?.gender?.value?.message}
            </p>
          )}
        </div>
      </div>
      <div className="profile__form-btn-container">
        {Object.keys(formState).length !== 0 && (
          <p
            className={
              "profile__form-message" +
              (formState.type === "success"
                ? " success-message"
                : formState.type === "fail"
                ? " error-message"
                : "")
            }
          >
            {formState.message}
          </p>
        )}
        <button type="submit" className="btn btn--primary profile__form-btn">
          Save
        </button>
      </div>
    </form>
  );
};

export default ChangeInfo;
