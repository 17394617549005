import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as PlusBlack } from "../../assets/images/plus-black.svg";
import { ReactComponent as MinusBlack } from "../../assets/images/minus-black.svg";
import { ReactComponent as DropdownIcon } from "../../assets/images/dropdown.svg";
import { ReactComponent as ClearIcon } from "../../assets/images/clear-icon.svg";
import CTAPopup from "./CTAPopup";

import { ticketLimit } from "../../constants";
import EventSummary from "./EventSummary";
import SeatsSelector from "../SeatsSelector/SeatsSelector";
import TicketsDetails from "../SeatsDetails/TicketsDetails";

const TicketingDetails = ({
  tickets = [],
  setTickets = () => {},
  layoutImage = null,
  selectedTickets = {},
  setSelectedTickets = () => {},
  handleTicketClick = () => {},
  handleTicketsFetch = () => {},
  loading = false,
  ticketCount = 0,
  setTicketCount = () => {},
  fetchError = "",
  setFetchError = () => {},
  expiry = null,
  cart = {},
  nextStep = () => {},
  handleRetry = () => {},
  event = {},
}) => {
  const [btnState, setBtnState] = useState({ state: "new", label: "fetch" });
  const [expandedTickets, setExpandedTickets] = useState({});
  const [priceMapper, setPriceMapper] = useState([]);

  const chartRef = useRef(null);

  useEffect(() => {
    getCount();
    if (Object.keys(cart).length !== 0) {
      let add = false;
      let remove = false;
      let same = true;
      if (Object.keys(cart).length === Object.keys(selectedTickets).length) {
        for (const ticket in selectedTickets) {
          if (cart[ticket]) {
            if (cart[ticket].quantity > selectedTickets[ticket].count) {
              remove = true;
              same = false;
            } else if (cart[ticket].quantity < selectedTickets[ticket].count) {
              add = true;
              same = false;
            }
          } else {
            add = true;
            same = false;
          }
        }
      } else {
        same = false;
        if (Object.keys(cart).length > Object.keys(selectedTickets).length) {
          remove = true;
          for (const ticket in cart) {
            if (
              selectedTickets[ticket] &&
              selectedTickets[ticket].count > cart[ticket].quantity
            ) {
              add = true;
            }
          }
        } else {
          add = true;
          for (const ticket in selectedTickets) {
            if (
              cart[ticket] &&
              cart[ticket].quantity < selectedTickets[ticket].count
            ) {
              remove = true;
            }
          }
        }
      }
      if (same) {
        setBtnState({ state: "nochange", label: "checkout" });
      } else {
        if (remove && !add) {
          setBtnState({ state: "remove", label: "checkout" });
        } else if (add && !remove) {
          setBtnState({ state: "add", label: "fetch" });
        } else if (add && remove) {
          setBtnState({ state: "change", label: "fetch" });
        }
      }
    }
  }, [selectedTickets]);

  useEffect(() => {
    if (event?.isSeated) {
      handleTicketsMapping();
    }
  }, [tickets]);

  useEffect(() => {
    if (
      tickets?.length !== 0 &&
      !event.isSeated &&
      !loading &&
      window?.innerWidth <= 768
    ) {
      const firstTicketDiv = document.getElementsByClassName(
        "event-page__ticket-area"
      )[0];
      const container = document.getElementsByClassName("event-page__body")[0];
      if (firstTicketDiv && container) {
        setTimeout(() => {
          container.scrollTo({
            top: firstTicketDiv?.getBoundingClientRect()?.top || 0,
            behavior: "smooth",
          });
        }, 1000);
      }
    }
  }, [tickets, loading, event]);

  const getCount = () => {
    if (Object.keys(selectedTickets).length === 0) {
      setTicketCount(0);
    } else {
      let sumValues = 0;
      for (const tier in selectedTickets) {
        sumValues += selectedTickets[tier].count;
      }
      setTicketCount(sumValues);
    }
  };

  const handleTicketsMapping = () => {
    let priceArray = [];
    tickets.forEach((ticket) => {
      priceArray.push({
        category: ticket?.name,
        price: ticket?.price,
      });
    });
    setPriceMapper(priceArray);
  };

  const handleChartTicketChange = async (choice, type) => {
    let index = tickets.findIndex(
      (ticket) =>
        ticket.name.toLowerCase() === choice.category.label.toLowerCase()
    );
    if (index >= 0) {
      handleTicketClick(
        tickets[index].id,
        tickets[index].name,
        tickets[index].price,
        type,
        choice.label
      );
    }
  };

  const handleChartLoaded = async (chart) => {
    chartRef.current = chart;
    let categories = await chartRef.current.listCategories();
    let ticketsCopy = [...tickets];
    ticketsCopy.forEach((ticket) => {
      let index = categories.findIndex(
        (category) => category.label.toLowerCase() === ticket.name.toLowerCase()
      );
      if (index >= 0) {
        ticket.color = categories[index].color;
      }
    });
    setTickets(ticketsCopy);
  };

  const handleClearTickets = () => {
    setSelectedTickets({});
    if (event?.isSeated) {
      chartRef.current.clearSelection();
    }
  };

  const handleDeselectSeat = (seat) => {
    chartRef.current.deselectObjects([seat]);
  };

  return (
    <React.Fragment>
      <div className="event-page__body">
        <div className="event-page__info event-page__info--padded">
          <div className="event-page__layout-image-area">
            {event?.isSeated ? (
              <SeatsSelector
                eventKey={event?.eventKey}
                priceMapper={priceMapper}
                onSelect={(selected) =>
                  handleChartTicketChange(selected, "add")
                }
                onDeselect={(deselected) =>
                  handleChartTicketChange(deselected, "subtract")
                }
                onChartLoaded={handleChartLoaded}
              />
            ) : (
              <img
                src={layoutImage}
                alt="event"
                className="event-page__layout-image"
              />
            )}
          </div>
        </div>
        <div className="event-page__description event-page__description--padded">
          <EventSummary event={event} />
          <h3 className="event-page__title event-page__title--ticket-selector">
            <span>Select Your Passes</span>
            {/* {ticketCount > 0 && (
              <span>
                <TicketsDetails
                  tickets={selectedTickets}
                  count={ticketCount}
                  deletable={true}
                  deleteSeat={(seat) => handleDeselectSeat(seat)}
                  clickable={event?.isSeated}
                />
              </span>
            )} */}
          </h3>
          <div className="event-page__ticket-subheader">
            <p className="event-page__ticket-max-warning">
              You are allowed to purchase a maximum of {ticketLimit} Passes
            </p>
            <p
              className="event-page__ticket-clear"
              onClick={() => {
                handleClearTickets();
              }}
            >
              {Object.keys(selectedTickets).length !== 0 && (
                <React.Fragment>
                  <ClearIcon className="event-page__ticket-clear-icon" /> clear
                  selection
                </React.Fragment>
              )}
            </p>
          </div>
          {tickets.map((ticket, index) => (
            <div
              className={
                "event-page__ticket-area" +
                (selectedTickets[ticket.id]
                  ? " event-page__ticket-area--selected"
                  : " event-page__ticket-area--not-selected")
              }
              key={index}
            >
              <div
                className="event-page__ticket-row"
                onClick={() => {
                  if (expandedTickets[ticket.id]) {
                    let old = { ...expandedTickets };
                    delete old[ticket.id];
                    setExpandedTickets(old);
                  } else {
                    setExpandedTickets({
                      ...expandedTickets,
                      [ticket.id]: true,
                    });
                  }
                }}
                style={{ cursor: ticket.description ? "cursor" : "default" }}
              >
                <div
                  className={
                    "event-page__ticket-name-wrapper" +
                    (ticket?.state?.type
                      ? " event-page__ticket-name-wrapper--" + ticket.state.type
                      : "")
                  }
                >
                  <p className="event-page__ticket-name">
                    {ticket.color && (
                      <span
                        className="event-page__ticket-color"
                        style={{ backgroundColor: ticket.color }}
                      ></span>
                    )}
                    {ticket.name}
                  </p>
                  {ticket?.state?.type !== "coming-soon" &&
                    ticket?.state?.type !== "sold-out" && (
                      <p className="event-page__ticket-price">
                        {ticket.price} EGP
                      </p>
                    )}
                </div>
                <React.Fragment>
                  <div
                    className={
                      "event-page__ticket-counter" +
                      (ticket?.state?.type
                        ? " event-page__ticket-counter--with-state"
                        : "")
                    }
                  >
                    {ticket?.state === null ? (
                      <React.Fragment>
                        {!event.isSeated && (
                          <button
                            className="event-page__ticket-counter-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleTicketClick(
                                ticket.id,
                                ticket.name,
                                ticket.price,
                                "subtract"
                              );
                            }}
                          >
                            <MinusBlack className="event-page__ticket-counter-icon" />
                          </button>
                        )}
                        <p className="event-page__ticket-count">
                          {selectedTickets[ticket.id]?.count
                            ? selectedTickets[ticket.id]?.count
                            : 0}
                        </p>
                        {ticketCount < ticketLimit && !event?.isSeated && (
                          <button
                            className="event-page__ticket-counter-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                !selectedTickets[ticket.id] ||
                                ticketCount < ticketLimit
                              ) {
                                handleTicketClick(
                                  ticket.id,
                                  ticket.name,
                                  ticket.price,
                                  "add"
                                );
                              }
                            }}
                          >
                            <PlusBlack className="event-page__ticket-counter-icon" />
                          </button>
                        )}
                      </React.Fragment>
                    ) : (
                      <p className="event-page__ticket-state">
                        {ticket?.state?.label}
                      </p>
                    )}
                  </div>
                </React.Fragment>
                <div className="event-page__ticket-dropdown-icon-wrapper">
                  {ticket.description && (
                    <DropdownIcon
                      className={
                        "event-page__ticket-dropdown-icon" +
                        (expandedTickets[ticket.id]
                          ? " event-page__ticket-dropdown-icon--expanded"
                          : "")
                      }
                    />
                  )}
                </div>
              </div>
              {ticket.description && (
                <div
                  className={
                    "event-page__ticket-description" +
                    (expandedTickets[ticket.id]
                      ? " event-page__ticket-description--expanded"
                      : "")
                  }
                >
                  {ticket.description}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {loading && (
        <CTAPopup
          actionHandler={null}
          variation="no-action"
          displayMessage="We are fetching your tickets, this could take a few moments.."
          showOverlay={true}
          loading={loading}
        />
      )}
      {!loading && !fetchError && (
        <CTAPopup
          actionHandler={() => {
            if (btnState.state === "nochange") {
              nextStep();
            } else {
              handleTicketsFetch();
            }
          }}
          title=""
          variation="single"
          text="Find Passes"
          /* text2={ticketCount === 0 ? "" : btnState.label} */
          disabled={Object.keys(selectedTickets).length === 0}
          expiry={expiry}
        />
      )}
      {!loading && fetchError && (
        <CTAPopup
          actionHandler={handleRetry}
          warning="OOPS!"
          displayMessage={fetchError}
          variation="error"
          text="Cancel"
          text2="Retry"
          cancel={() => setFetchError("")}
          showOverlay={true}
        />
      )}
    </React.Fragment>
  );
};

export default TicketingDetails;
